var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"step-title"},[_c('span',[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.experimentalStep.title")))])])])]),_c('div',{staticClass:"experimentalSectionsContainer list",attrs:{"id":"experimentalSectionsContainer"}},_vm._l((this.subscription.experimentalSections),function(sectionId,index){return _c('div',{key:index},[_c('div',{class:'section section-' + index},[_c('h4',{staticClass:"level1"},[_vm._v(" "+_vm._s(sectionId.title)+" ")]),_vm._l((sectionId.groups),function(group,index2){return _c('div',{key:index2},[_c('div',{class:'group group-' + index2 + ' nb-gear-' + _vm.gearNumber},[_vm._l((group.gears),function(gear,index3){return _c('div',{key:index3},[_c('div',{class:'gear gear-' +
                    index3 +
                    ' nb-column-' +
                    _vm.allowedCatchTypesNumber},[_c('h5',[_vm._v(" "+_vm._s(gear.type.description)+" ")]),_c('div',{class:'row column-' + _vm.allowedCatchTypesNumber + ' title'},_vm._l((gear.allowedCatchTypes),function(catchTypeId,index4){return _c('div',{key:index4},[_c('h6',[_vm._v(" "+_vm._s(catchTypeId.abbrv)+" ")])])}),0),_vm._l((_vm.NbRowForAnExperimentalGear[index3]),function(numberForExGear,index5){return _c('div',{key:index5},[_c('div',{class:'row nb-column-' + _vm.allowedCatchTypesNumber},_vm._l((gear.allowedCatchTypes),function(catchTypeId,index6){return _c('div',{key:index6},[_c('ExperimentalInput',{attrs:{"id":'experimentalCatch-' +
                            index3 +
                            '-' +
                            index6 +
                            '-' +
                            (index5 + 1),"min":0,"max":catchTypeId.value_max,"digits":true,"refeshError":_vm.showErrorData,"errorMsg":'MAX ' + catchTypeId.value_max,"type":"text"},on:{"error":_vm.addErrors,"input":function($event){return _vm.handleInput(index3, index5)}},model:{value:(
                          _vm.form.experimentalData[index3][index5 + 1][index6]
                        ),callback:function ($$v) {_vm.$set(_vm.form.experimentalData[index3][index5 + 1], index6, $$v)},expression:"\n                          form.experimentalData[index3][index5 + 1][index6]\n                        "}})],1)}),0)])})],2)])}),_c('p',{staticClass:"clearBother"})],2)])})],2)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }